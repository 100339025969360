/* Bootstrap  */
//import "bootstrap";
//import { Modal } from 'bootstrap'
import { Tab, Collapse, Dropup } from 'bootstrap'

/* Custom SCSS */
import "./index.scss";

/* SwiperJS */
import Swiper , { Navigation, Pagination, Scrollbar, Autoplay, Mousewheel } from 'swiper';
import 'swiper/css';
Swiper.use([Navigation, Autoplay, Scrollbar, Pagination, Mousewheel]);

var baseUrl = window.location;
let $homeurl ="";
if(baseUrl.host == 'localhost:8888'){
  $homeurl = "http://localhost:8888/scab/front/";
}else{
  if ($("body").hasClass("lang-en")) {
    $homeurl = baseUrl.protocol+'//'+baseUrl.host+'/en/';
  }else if ($("body").hasClass("lang-fr")) {
    $homeurl = baseUrl.protocol+'//'+baseUrl.host+'/fr/';
  }else if ($("body").hasClass("lang-de")) {
    $homeurl = baseUrl.protocol+'//'+baseUrl.host+'/de/';
  }else{
    $homeurl = baseUrl.protocol+'//'+baseUrl.host+'/';
  }
  
}

// ----------------------------------------------------
// Adding the placeholders in textfields of login form 
// ----------------------------------------------------


jQuery(document).ready(function($) {
  if ($("body").hasClass("tax-tipo_prodtax")) {
    setTimeout(
      function() 
      {
        $(window).scrollTop(0);
      }, 1000);
  }

	$('#loginform input[type="text"]').attr('placeholder', 'Username');
	$('#loginform input[type="password"]').attr('placeholder', 'Password');
	
	$('#loginform label[for="user_login"]').contents().filter(function() {
		return this.nodeType === 3;
	}).remove();
	$('#loginform label[for="user_pass"]').contents().filter(function() {
		return this.nodeType === 3;
	}).remove();
	
	$('#loginform input[type="checkbox"]').click(function() {
		$(this+':checked').parent('label').css("background-position","0px -20px");
		$(this).not(':checked').parent('label').css("background-position","0px 0px");
	});

});


$('.open_filters_mob').click(function(){
  $(this).toggleClass("active");
  if ($("body").hasClass("post-type-archive-realizzazioni")) {
    $('.filter_wrp').toggleClass("open");
  }else{
    $('.category_navigation').toggleClass("open");
  }
  
});

//import 'swiper/css/navigation';
//import 'swiper/css/pagination';
//import 'swiper/css/scrollbar';
//Swiper.use([Scrollbar]);
// init Swiper:
/*
const swiper = new Swiper('.swiper', {  direction: 'vertical',
    loop: true,

    // If we need pagination
    pagination: {
        el: '.swiper-pagination',
    },

    // Navigation arrows
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },

    // And if we need scrollbar
    scrollbar: {
        el: '.swiper-scrollbar',
    },
    modules: [Navigation, Pagination, Scrollbar],
    ...
});
*/

/* Fancybox */
//import { Fancybox } from "@fancyapps/ui";
//import "@fancyapps/ui/dist/fancybox.css";

/* Gsap */
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

//var Isotope = require('isotope-layout');

// window.onbeforeunload = () => {
//   window.scrollTo(0, 0);
// }


// var tag = document.createElement("script");
// tag.src = "https://www.youtube.com/iframe_api";
// var firstScriptTag = document.getElementsByTagName("script")[0];
// firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

jQuery(document).ready( function($) { 


  if(window.matchMedia("(max-width: 767px)").matches){
    $('body').on('click', '.dropdown', function() {
          $('.nav_bottom').toggleClass('active');
      });
  } 
  
  /* Custom Cursor */

  const $bigCursor = document.querySelector('.cursor__ball--big');
const $smallCursor = document.querySelector('.cursor__ball--small');
const $hoverzoom = document.querySelectorAll('.hover_zoom');
const $hoverzoomout = document.querySelectorAll('.hover_zoomout');
const $hoverSlider = document.querySelectorAll('.swiper');
const $hovercta = document.querySelectorAll('.standard_cta_animated');

const $hoveraccordion= document.querySelectorAll('#accordion_finiture .accordion-body');


// Listeners
document.body.addEventListener('mousemove', onMouseMove);
for (let i = 0; i < $hoverzoom.length; i++) {
  $hoverzoom[i].addEventListener('mouseenter', onMouseHover);
  $hoverzoom[i].addEventListener('mouseleave', onMouseHoverOut);
}

for (let i = 0; i < $hoverSlider.length; i++) {
  $hoverSlider[i].addEventListener('mouseenter', onMouseHover);
  $hoverSlider[i].addEventListener('mouseleave', onMouseHoverOut);
}

for (let c = 0; c < $hoverzoomout.length; c++) {
  $hoverzoomout[c].addEventListener('mouseenter', onMouseHoverZoomOut);
  $hoverzoomout[c].addEventListener('mouseleave', onMouseHoverOutZoomOut);
}

for (let c = 0; c < $hovercta.length; c++) {
  $hovercta[c].addEventListener('mouseenter', onMouseHoverZoomOut);
  $hovercta[c].addEventListener('mouseleave', onMouseHoverOutZoomOut);
}

for (let c = 0; c < $hoveraccordion.length; c++) {
  $hoveraccordion[c].addEventListener('mouseenter', onMouseHoverVertical);
  $hoveraccordion[c].addEventListener('mouseleave', onMouseHoverOutVertical);
}


// Move the cursor
function onMouseMove(e) {
  gsap.to($bigCursor, .4, {
    x: e.x - 10,
    y: e.y - 10
  })
  gsap.to($smallCursor, .1, {
    x: e.x - 5,
    y: e.y - 7
  })
}

var is_safari = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1 &&  navigator.userAgent.indexOf('Android') == -1;

let scaleBig ="";
let scaleMedium ="";
let scaleSmall ="";
if (is_safari) {
  scaleBig =1.3;
  scaleMedium =.1;
  scaleSmall =.02;

  gsap.to($bigCursor, .3, {
    scale: scaleMedium,
    opacity:0.2
  })
}else{
   scaleBig =6;
   scaleMedium =1;
   scaleSmall =.1;
}


// Hover an element
function onMouseHover() {
  gsap.to($bigCursor, .3, {
    scale: scaleBig,
    opacity:1
  })
}
function onMouseHoverOut() {
  gsap.to($bigCursor, .3, {
    scale: scaleMedium,
    opacity:0.2
  })
}

function onMouseHoverZoomOut() {
  gsap.to($bigCursor, .3, {
    scale: scaleSmall
  })
  gsap.to($smallCursor, .3, {
    scale: scaleSmall
  })
}
function onMouseHoverOutZoomOut() {
  gsap.to($bigCursor, .3, {
    scale: scaleMedium
  })
  gsap.to($smallCursor, .3, {
    scale: scaleMedium
  })
}

function onMouseHoverVertical() {
  gsap.to($bigCursor, .3, {
    scale: scaleBig,
    rotation:90,
    opacity:1
  })
  gsap.to($smallCursor, .3, {
    scale: scaleBig,
    rotation:90,
    opacity:1
  })
}
function onMouseHoverOutVertical() {
  
  gsap.to($bigCursor, .3, {
    scale: scaleMedium,
    rotation:0,
    opacity:0.2
  })
  gsap.to($smallCursor, .3, {
    scale: scaleMedium,
    rotation:0,
    opacity:0.2
  })

}

  /* End Custom Cursor */

  /* animazione spinner */

  if ($("body").hasClass("archive")) {

  const tl_loader = gsap.timeline({paused:true, onComplete: showSpinner,});

  tl_loader.to('.loader',{
    width:'100%',
    height:'100%',
    ease: "slow(0.1,0.7,true)",
    duration: 0.1,
  })

  tl_loader.to('.loader',{
    opacity:1,
    ease: "slow(0.1,0.7,true)",
    duration: 1,
  })

  const tl_logospinner = gsap.timeline({repeat: 3, repeatDelay: 1.3, paused:true});

  tl_logospinner.to('.logo_mask_stroke',{
    width:'100%',
    ease: "slow(0.1,0.7,true)",
    duration: 1,
  })

  // tl_logospinner.to('.logo_mask', {
  //   height:'100%',
  //   ease: "slow(0.1,0.7,true)",
  //   duration: 1,
  // })

  // tl_logospinner.to('.logo_dot', {
  //   opacity:1,
  //   ease: "slow(0.1,0.7,true)",
  //   duration: .5,
  // })


  tl_logospinner.to('.logo_mask_stroke',{
    opacity:0,
    ease: "slow(0.1,0.7,true)",
    duration: .5,
  })

  // tl_logospinner.to('.logo_mask', {
  //   opacity:0,
  //   ease: "slow(0.1,0.7,true)",
  //   duration: .5,
  // },'<')

  // tl_logospinner.to('.logo_dot', {
  //   opacity:0,
  //   ease: "slow(0.1,0.7,true)",
  //   duration: .5,
  // },'<')



  //tl_loader.play();

  function showSpinner(){
    tl_logospinner.play();
  }
 
}

  /* end animazione spinner */


  /* Animation on single prod card */
  hoverEffect();

  function hoverEffect(){

    const $btn_arrow_inverted_wrp = gsap.utils.toArray('.tease-prod');

  $btn_arrow_inverted_wrp.forEach(btn => {
    const $btn_arrow_inverted_back = btn.querySelector(".back");
    const $btn_arrow_inverted_text = btn.querySelector(".text"); 
  
  
    const tl = gsap.timeline({paused:true});
  
      tl.to($btn_arrow_inverted_back, {
        height:'100%',
        left:0,
        ease: "slow(0.1,0.7,true)",
        duration: .5,
      })
      tl.to($btn_arrow_inverted_back, {
        width:'100%',
        ease: "slow(0.1,0.7,true)",
        duration: .2,
      })
      tl.to($btn_arrow_inverted_text, {
        opacity:1,
        ease: "slow(0.1,0.7,true)",
        duration: .5,
      })
      
  
      btn.animation = tl;
  
      btn.addEventListener("mouseenter", function(){
        this.animation.play();
      });
  
      btn.addEventListener("mouseleave", function(){
        this.animation.reverse();
      })
  
  
  });

  }
  

  /* End Animation on single prod card */

  /* Archive navigation */
  let $termslug = '';
  $('.category_navigation input').click(function() {
    $termslug = $(this).data("slug");
    console.log('Ci siamo');
    let $archivetax = "";
    if ($("body").hasClass("lang-en")) {
       $archivetax = $homeurl+'selection/'+$termslug;
       console.log('Ci siamo eng');
    }else if ($("body").hasClass("lang-fr")) {
      $archivetax = baseUrl.protocol+'//'+baseUrl.host+'/fr/selection/'+$termslug;
      console.log('Ci siamo fr');
    }else if ($("body").hasClass("lang-es")) {
      $archivetax = baseUrl.protocol+'//'+baseUrl.host+'/es/selezione/'+$termslug;
      console.log('Ci siamo es');
      console.log( $homeurl);
    }else if ($("body").hasClass("lang-de")) {
      $archivetax = baseUrl.protocol+'//'+baseUrl.host+'/de/selezione/'+$termslug;
      console.log('Ci siamo de');
    }else{
       $archivetax = $homeurl+'selezione/'+$termslug;
       console.log('Ci siamo other');
    }
    console.log( $homeurl);
    window.location.href = $archivetax;
  });





  /* Archvio caratteristiche prodotto */

  $('.tax-caratteristica-prodotto .archive_caratteristiche_prodotto .sidebar .sidebar_btn').click(function() {

    let $termid = $(this).data("filter");

    $('.archive_caratteristiche_prodotto .sidebar .sidebar_btn').each(function(i, obj) {
      $(this).removeClass('filtro_attivo');
    });
    $(this).toggleClass('filtro_attivo');
    var nav = $('.filter_wrp');
    if (nav.length) {
      //console.log($("#1576").position().top);
    }
   // document.getElementById($termid).scrollIntoView();

   const yOffset = -200; 
    const element = document.getElementById($termid);
    const y = element.getBoundingClientRect().top + window.scrollY + yOffset;

    window.scrollTo({top: y, behavior: 'smooth'});
   
  });

  /* Effetto zoom per materiali */

  let currentZoom = 1.0;
  // $('.archive_caratteristiche_prodotto .img_wrp').click(function() {
  //   $(this).toggleClass('zoom_img');
  //   console.log('animate');
  //   let a = $(this).html();
  //   let b = $('.zoom_img').html(a);
  //   $('.zoom_img').toggleClass('zoom_img_active');
  // });

  $('.single_materiale').click(function() {


    if ($(this).hasClass("zoom_img_active")) {
      $('.single_materiale').each(function(i, obj) {
      
        $(this).removeClass('zoom_img_active');
      });
      //$(this).removeClass('zoom_img_active');
    }else{
      $('.single_materiale').each(function(i, obj) {
      
        $(this).removeClass('zoom_img_active');
      });
      $(this).toggleClass('zoom_img_active');
    }
    
    // $(".zoom_img").empty(); // fadeOut will hide the element, no need to hard-code css method 
    // $('.zoom_img').toggleClass('zoom_img_active');
    // $(this).toggleClass('img_wrp_zoom_img_active');
  })

//   $(document).on('mousemove', function(e){
//     $('.zoom_img_active').css({
//        left:  e.pageX,
//        top:   e.pageY
//     });
// });

/* fine Effetto zoom per materiali */

  /* End Archive navigation */


  /* Archive AJAX filtering */

  var url = $(location).attr('href');
  var parts = url.split("/");
  var last_part = parts[parts.length-2]
  $termslug = last_part;

  var $results_per_page = 40;
  
  var $ajax_type = "";

  if (parts.indexOf('selezione') !== -1) {
    
    $ajax_type = 'Prodotto'
  }else if (parts.indexOf('selection') !== -1) {
    $ajax_type = 'Prodotto'
  }else if (parts.indexOf('s&#232;lection') !== -1) {
    $ajax_type = 'Prodotto'
  }else if (parts.indexOf('s&eacute;lection') !== -1) {
    $ajax_type = 'Prodotto'
  }else if (parts.indexOf('category') !== -1) {
    $ajax_type = 'News'
  }else{
    $ajax_type = 'Realizzazioni';
  }


  let $filtri_collezioni = [];
  let $filtro_indoorOutdoor = []; 
  let currentPage = 1;
  var loadMore = true;

  let $total_results_query = getComputedStyle(document.documentElement).getPropertyValue('--nr-totla-products');
  $total_results_query = parseInt(  $total_results_query );
  let $total_results_shown = currentPage * $results_per_page;
  let $totalpages = $('.loop_wrp_cards').attr("total-pages");
  let $totalpostsonload = $('.loop_wrp_cards').attr("total-posts");

  console.log($totalpostsonload);
  console.log($results_per_page);

 if($totalpostsonload > $results_per_page ){
  $('.load_more_products').css('display','block');
 }else{
  $('.load_more_products').css('display','none');
 }





  /* Se siamo in archvio documenti */
  $('body').on('click', '.search_form_docs .searchdocs_btn', function(e) {
    e.preventDefault();
    removeAllActiveDocs();
    $('.no_results_found').css('display','block');
     var $single_doc = $('.search_form_docs .search_input').val().toLowerCase();
  //   var $single_doc_first = '[id*='+$single_doc+']';
  //   $($single_doc_first).first().addClass('active');
  //   $([document.documentElement, document.body]).animate({
  //     scrollTop: $($single_doc_first).first().offset().top
  // }, 2000);
  $('.single_prod_docs').each(function() {
    let active_row = $(this);
    $(this).find('.col').each(function() {
      
      let str = $(this).text().toLowerCase();
      if(str.includes($single_doc)){
        active_row.addClass('row_active');
      }
    })
  });

  if ($(".row_active")[0]){
      // Do something if class exists
      $('.no_results_found').css('display','none');
  } else {
    $('.no_results_found').css('display','block');
  }
  

  //   $([document.documentElement, document.body]).animate({
  //     scrollTop: $('.row_active').first().offset().top - 200
  // }, 2000);

  $('.blocco_preview_docs').animate({
    scrollTop: $('.row_active').first().offset().top + $('.blocco_preview_docs').scrollTop()
  }, 2000);
  
  });

  function removeAllActiveDocs(){
    $('.single_prod_docs').each(function() {
      $(this).removeClass('row_active');
    });
  }
  
  /* fine documenti */

  /* Se siamo inn archivio realizzazioni */

  $('body').on('click', '.collezione_sub_field', function(e) {
    e.preventDefault();
    $filtri_collezioni = [];
    $('.filter_wrp a').each(function() {
      $(this).removeClass('active_filter');
    })
    $(this).addClass('active_filter');
    let $filter_name = $(this).data("filter");
        // let filter_name_val = $filter_name.replace(' ', '');
        // let lowercase_filter_nanme_stripped_val = filter_name_val.toLowerCase();
         $filtri_collezioni.push($filter_name);
         $filtro_indoorOutdoor = "";
     
        currentPage=1;
        sendAjax();
  });

  $('body').on('click', '.collezione_sub_field_filter', function(e) {
    e.preventDefault();
    $filtri_collezioni = [];
    $('.collezione_sub_field').each(function() {
      $(this).removeClass('active_filter');
      $(this).closest( "h2" ).removeClass('active_btn');

    })
    $(this).addClass('active_filter');
    $(this).closest( "h2" ).addClass('active_btn');
    
    let $filter_name = $(this).data("filter");
        // let filter_name_val = $filter_name.replace(' ', '');
        // let lowercase_filter_nanme_stripped_val = filter_name_val.toLowerCase();
         $filtri_collezioni.push($filter_name);
         $filtro_indoorOutdoor = "filtro_realizzazioni";
     
        currentPage=1;
        sendAjax();
  });
  /* fine archivio realizzazioni */

    /* Se siamo in archivio prodotti */
    $('body').on('change', '#accordion_filtri .form-check-input', function(e) {
      $filtri_collezioni = [];
      $('#accordion_filtri .form-check-input').each(function(i, obj) {
        if ($(this).is(':checked')) {
          let $filter_name = $(this).data("filter");
          let filter_name_val = $filter_name.replace(' ', '');
          let lowercase_filter_nanme_stripped_val = filter_name_val.toLowerCase();
          $filtri_collezioni.push(lowercase_filter_nanme_stripped_val);
  
         
        }
      });
      currentPage=1;
      sendAjax();
    });
  
    let $new_filter="";
  $('body').on('click', '#news_home .sidebar .sidebar_btn', function(e) {
    //e.preventDefault();

    $new_filter = '';

    $(this).toggleClass('filtro_attivo');

    $filtro_indoorOutdoor = [];
    
    $('#news_home .sidebar .filtro_attivo').each(function(i, obj) {
      
      let $selected_filter = $(this).data("filter");
      if($selected_filter == 'new'){
          console.log('siamo in new');
        $new_filter = '1';
        $('.load_more_products').css('display','none');
      }else{
        $new_filter = '';
        console.log('non siamo in new');
      
      }
      let stripped_val = $selected_filter.replace(' ', '');
      let lowercase_stripped_val = stripped_val.toLowerCase();
      $filtro_indoorOutdoor.push(lowercase_stripped_val);
      
    });
    currentPage=1;
    sendAjax();
  })

  /* Click on Load More */
  
  $('body').on('click', '.load_more_products', function(e) {

    if( $termslug == 'news'){
      $filtro_indoorOutdoor = 'news';
    }else if( $termslug == 'press'){
      $filtro_indoorOutdoor = 'press';
    }else if( $termslug == 'press-fr'){
      $filtro_indoorOutdoor = 'press';
    }else if( $termslug == 'press-de'){
      $filtro_indoorOutdoor = 'press';
    }

    console.log('Parti: '+parts);
    console.log('termslug:'+$termslug );
    
    let $page_left = parseInt($totalpages - currentPage);
    console.log($page_left);
    if($page_left  > 0.1){
      currentPage++; 
      loadMore=false; 
      sendAjax();
      
      
    }else{
      currentPage++; 
      sendAjax();
      $(this).css('display','none');
      console.log('Siamo ultima'); 

      console.log(currentPage); 

    }

    
    
  })

  function sendAjax(){
   
 /* Decommentare se si vuole Inserire i temrini usati come filtro */

    // $('.active_filters .filters_archive').empty();

    // $('.filtri_title').empty();

    // if($filtri_collezioni.length > 0 || $filtro_indoorOutdoor.length > 0){
    //   $('.filtri_title').append("Filtri attivi:");
    // }

    // $filtri_collezioni.forEach(function(item) {
      
    //   $('.active_filters .filters_archive').append(" / "+item);
    // });

    // $filtro_indoorOutdoor.forEach(function(item) {
  
    //   $('.active_filters .filters_archive').append(" / "+item);
    // });
    
 /* fine termini usati come filtro */   


console.log($termslug);

console.log($termslug);
console.log($filtro_indoorOutdoor);
console.log($new_filter);
console.log($ajax_type);


    jQuery.ajax({
      type: "POST",
      url: ajax_auth_object.ajaxurl,
      context: this,
      data: {

          security: ajax_auth_object.security,
          action: 'load_form_project_pdf',
          project_id: $(this).data('id'),
          'archive_tipo':  $termslug,
          'term_collezioni': $filtri_collezioni,
          'indoor_outdoor' : $filtro_indoorOutdoor,
          'languagecode': ajax_auth_object.languagecode,
          'new_filter':$new_filter,
          'ajax_type': $ajax_type,
          paged: currentPage
      },
      beforeSend: function() {
        tl_loader.play();
      },
      success: function (data) {
        

        

        // if($total_results_query > $total_results_shown ){
        //   $('.load_more_products').css('display','none');
        // }else{
        //   $('.load_more_products').css('display','block');
        // }

        


        let $total_results_data = Number($(":root").css("--nr-totla-products"));
        let $total_results_data_string = $(":root").css("--nr-totla-products");
        console.log( $total_results_data_string );
        if ( $total_results_data_string === undefined ){
          setTimeout(function() {
            $total_results_data = Number($('.realiazzioni_row').attr("data-results"));
            console.log( $total_results_data );
          }, 100);
        }
       //let $total_results_data = $('.realiazzioni_row').data("results");
          console.log( $results_per_page );
          
          console.log( currentPage );
          let $is_mora_visible = $results_per_page * currentPage;
          console.log( $is_mora_visible );

        if($filtri_collezioni.length > 0){
          if( $( "body" ).hasClass( "post-type-archive-realizzazioni" ) ){
            if( currentPage > 1 ){
              $('.loop_wrp_cards').append(data);
            }else{
              $('.loop_wrp_cards').html(data);
            }
            
          }else{
            $('.loop_wrp_cards').html(data);
            console.log('Siamo in else');
          }       
          $('.reset_filter').css('display', 'flex');
          $( ".reset_filter" ).fadeIn( "slow", function() {});
        }else if($filtro_indoorOutdoor.length > 0){
          if($termslug == 'news' || $termslug == 'press' || $termslug == 'press-fr' || $termslug == 'press-de'){
            $('.loop_wrp_cards').append(data);
          }else{
            $('.loop_wrp_cards').html(data);
          }
          
          $('.reset_filter').css('display', 'flex');
          $( ".reset_filter" ).fadeIn( "slow", function() {});
          }else if(currentPage > 0.3){
            console.log('Siamo in else 0.3');
            $('.loop_wrp_cards').append(data);
          }else{
            console.log('Siamo in else html');
            $( ".loop_wrp_cards" ).html( $( data ) );
          }

        

        if( $( "body" ).hasClass( "post-type-archive-realizzazioni" ) ){

          
          if( $is_mora_visible > Number($total_results_data) ){
            console.log('hide load more');
            $('.load_more_products').css('display','none');
          }

        }else{
          if(currentPage > Math.floor($totalpages)){
            $('.load_more_products').css('display','none');
          }
        }

        
        // setTimeout(
        //   function() 
        //   {
        //     let $total_results_data = $(":root").css("--nr-totla-products");
        //     if($total_results_data < $results_per_page){
        //       $('.load_more_products').css('display','none');
              
        //     }else{
        //       $('.load_more_products').css('display','block');
        //     }
        //   }, 500);

          

        hoverEffect();
        loadMore = true;
          //$('.loop_wrp').html(data);
          tl_loader.reverse();
          checkonScreen();
          console.log('success');

          
          
      }
    });
    
  }

  /* End Archive filtering */

  /* Archive LoadMore */
  // $(window).scroll(function () {
  //   if ($(window).scrollTop() + $(window).height() > $('.woocommerce-pagination').offset().top) {
  //     if(loadMore){ 
  //       currentPage++; 
  //       sendAjax();
  //       loadMore=false; 
  //       console.log(currentPage);
  //     }
      
  //   }else{
  //     console.log('currentPage');
  //   }
  // });
  /* End Archive LoadMore */
  if ($(".scroll_container").length) {

    if(window.matchMedia("(max-width: 940px)").matches){
    }else{

      let sections = gsap.utils.toArray(".panel");

      gsap.to(sections, {
        xPercent: -100 * (sections.length - 1),
        ease: "none",
        scrollTrigger: {
          trigger: ".scroll_container",
          pin: true,
          scrub: 1,
          snap: 1 / (sections.length - 1),
          end: () => "+=" + document.querySelector(".scroll_container").offsetWidth
        }
      });

    }
  }


   /* Pannello di login */

   let tl_login = gsap.timeline({
    paused: true
  });
  
  tl_login.to('.background_overlay', {
    height:'100vh',
    opacity:.6,
    duration: .5,
    ease: "sine.inOut",
  });

   tl_login.to('.login_panel', {
    opacity:1,
    top:0,
    duration: .7,
    delay:.2,
    ease: "sine.inOut",
   });
   tl_login.to('.login_panel .login_form_wrp', {
    opacity:1,
    duration: .8,
    delay: .5,
    ease: "sine.inOut",
   });
   tl_login.to('.login_panel .rgister_panel .wpcf7', {
    opacity:1,
     duration: .8,
         ease: "sine.inOut",
   });
 
   
   $('body').on('click', '.open_login', function(e) {
     e.preventDefault();
     tl_login.play();
   });

   $('body').on('click', '.login_panel .close_panel', function(e) {
   e.preventDefault();
   tl_login.reverse();
 });

 /* Pannello Search */

 let tl_search = gsap.timeline({
  paused: true
});

tl_search.to('.background_overlay', {
  height:'100vh',
  opacity:.6,
  duration: .5,
  ease: "sine.inOut",
});

tl_search.to('.search_panel', {
   opacity:1,
   top:0,
   duration: .7,
   delay:.2,
  ease: "sine.inOut",
 },'<');

 tl_search.to('.search_panel .search_panel_wrp .search_form', {
  opacity:1,
   duration: .8,
       ease: "sine.inOut",
 });



 
 $('body').on('click', '.search_btn', function(e) {
   e.preventDefault();
   tl_search.play();
 });

 $('body').on('click', '.search_panel .close_panel', function(e) {
 e.preventDefault();
 tl_search.reverse();
});


/* Pannello di newsletter */

let tl_newslt = gsap.timeline({
  paused: true
});

tl_newslt.to('.background_overlay', {
  height:'100vh',
  opacity:.6,
  duration: .5,
  ease: "sine.inOut",
});

tl_newslt.to('.lnews_panel', {
  opacity:1,
  top:0,
  duration: .7,
  delay:.2,
  ease: "sine.inOut",
 });
//  tl_newslt.to('.lnews_panel .login_form_wrp', {
//   opacity:1,
//   duration: .8,
//   delay: .5,
//   ease: "sine.inOut",
//  });
 tl_newslt.to('.lnews_panel .rgister_panel .wpcf7', {
  opacity:1,
   duration: .8,
       ease: "sine.inOut",
 });
//  tl_newslt.to('.lnews_panel .login_form_wrp .wpcf7', {
//   opacity:1,
//    duration: .8,
//        ease: "sine.inOut",
//  });


 
 $('body').on('click', '.open_news', function(e) {
   e.preventDefault();
   tl_newslt.play();
 });

 $('body').on('click', '.lnews_panel .close_panel', function(e) {
 e.preventDefault();
 tl_newslt.reverse();
});


/* Pannello LIngue */

let tl_language = gsap.timeline({
  paused: true
});

tl_language.to('.background_overlay', {
  height:'100vh',
  opacity:.6,
  duration: .5,
  ease: "sine.inOut",
});

tl_language.to('.lang_panel', {
  opacity:1,
  top:0,
  duration: .7,
  delay:.2,
  ease: "sine.inOut",
 });
 tl_language.to('.lang_panel a', {
  opacity:1,
  duration: .8,
  delay: .5,
  ease: "sine.inOut",
  stagger: 0.3 
 });



 
//  $('body').on('click', '.open_lang', function(e) {
//    e.preventDefault();
//    tl_language.play();
//  });

 $('body').on('click', '.lang_panel .close_panel', function(e) {
 e.preventDefault();
 tl_language.reverse();
 console.log('Ci siamo chiuso lang');
});


/* Pannello di Contatto */

let tl_contact = gsap.timeline({
  paused: true
});

tl_contact.to('.background_overlay', {
  height:'100vh',
  opacity:.6,
  duration: .5,
  ease: "sine.inOut",
});

tl_contact.to('.contact_panel', {
  opacity:1,
  top:0,
  duration: .7,
  delay:.2,
  ease: "sine.inOut",
 });
 tl_contact.to('.contact_panel .login_form_wrp', {
  opacity:1,
  duration: .8,
  delay: .5,
  ease: "sine.inOut",
 });

 tl_contact.to('.contact_panel .contact_panel_2', {
  opacity:1,
  duration: .8,
  delay: .5,
  ease: "sine.inOut",
 });

 
 tl_contact.to('.contact_panel .rgister_panel .wpcf7', {
  opacity:1,
   duration: .8,
       ease: "sine.inOut",
 });
//  tl_contact.to('.contact_panel .login_form_wrp .wpcf7', {
//   opacity:1,
//    duration: .8,
//        ease: "sine.inOut",
//  });


 
 $('body').on('click', '.open_contact', function(e) {
   e.preventDefault();
   tl_contact.play();
 });

 $('body').on('click', '.contact_panel .close_panel', function(e) {
 e.preventDefault();
 tl_contact.reverse();
 console.log('Ci siamo chiuso contatti');
});


/* Pannello di Contatto OneToOne */

let tl_onToone = gsap.timeline({
  paused: true
});

tl_onToone.to('.background_overlay', {
  height:'100vh',
  opacity:.6,
  duration: .5,
  ease: "sine.inOut",
});

tl_onToone.to('.onetoone_panel', {
  opacity:1,
  top:0,
  duration: .7,
  delay:.2,
  ease: "sine.inOut",
 });

 tl_onToone.to('.onetoone_panel .login_form_wrp', {
  opacity:1,
  duration: .8,
  delay: .5,
  ease: "sine.inOut",
 });

 tl_onToone.to('.onetoone_panel .contact_panel_2', {
  opacity:1,
  duration: .8,
  delay: .5,
  ease: "sine.inOut",
 });

 
 tl_onToone.to('.onetoone_panel .contact_panel_2 .wpcf7', {
  opacity:1,
   duration: .8,
       ease: "sine.inOut",
 });



 
 $('body').on('click', '.open_onetotone', function(e) {
  console.log('Ope OnetoOne');
   e.preventDefault();
   tl_onToone.play();
 });

 $('body').on('click', '.onetoone_panel .close_panel', function(e) {
 e.preventDefault();
 tl_onToone.reverse();
});




/* Pannello di Wishlist */

let tl_wish = gsap.timeline({
  paused: true
});

tl_wish.to('.background_overlay', {
  height:'100vh',
  opacity:.6,
  duration: .5,
  ease: "sine.inOut",
});



tl_wish.to('.wishlist_panel', {
  opacity:1,
  top:0,
  duration: .7,
  delay:.2,
  ease: "sine.inOut",
 });

 tl_wish.to('.wishlist_panel .wishlist_panel_title', {
  opacity:1,
  duration: .8,
  delay: .5,
  ease: "sine.inOut",
 });

//  tl_wish.to('.wishlist_panel .login_form_wrp', {
//   opacity:1,
//   duration: .8,
//   delay: .5,
//   ease: "sine.inOut",
//  });
 
//  tl_wish.to('.wishlist_panel .ccc-my_favorite-list', {
//   opacity:1,
//    duration: .8,
//        ease: "sine.inOut",
//  });



 
 $('body').on('click', '.wish_list', function(e) {
  console.log('Ope OnetoOne');
   e.preventDefault();
   tl_wish.play();
 });

 $('body').on('click', '.wishlist_panel .close_panel', function(e) {
 e.preventDefault();
 tl_wish.reverse();
});

   
const swiperSlider_extra2 = new Swiper(".prod_gallery_extra", {

  slidesPerView: "auto",
   spaceBetween: 23,
   centeredSlides: false,
   loop: true,
   freeMode: true,
   mousewheel: false,
   scrollbar:false,
   pagination: {
     el: ".swiper-pagination",
     clickable: true,
   },
});

const swiper_relatedprods_wrp_related = new Swiper(".relatedprods_wrp_related", {
  loop: true,
        slidesPerView: "auto",
        spaceBetween: 22,
        centeredSlides: false,
          loop: true,
          freeMode: false,
          mousewheel: false,
          scrollbar:false,
});


   if( $( "body" ).hasClass( "single-prodotti" ) ){

      const swiperSlider = new Swiper(".prod_gallery_1", {
        slidesPerView: 1,
        spaceBetween: 16,
        freeMode: true,
        mousewheel: false,
        scrollbar:false,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        breakpoints: {
          640: {
            slidesPerView: 2,
           
          },
        },
      });

      const swiperSlider_realizzazioni = new Swiper(".realization_wrp_same_collection_slider", {
        loop: true,
        slidesPerView: "auto",
        spaceBetween: 22,
        centeredSlides: false,
          loop: true,
          freeMode: false,
          mousewheel: false,
          scrollbar:false,
      });

      


      const swiper_related_same_collection = new Swiper(".relatedprods_wrp_same_collection_slider", {
        loop: true,
        slidesPerView: "auto",
        spaceBetween: 22,
        centeredSlides: false,
          loop: true,
          freeMode: false,
          mousewheel: false,
          scrollbar:false,
    });

    
   




    $(".archi_iframe_wrp").click(function() {  //use a class, since your ID gets mangled
      $(this).addClass("archi_iframe_wrp_active");      //add the class to the clicked element
    });  

    if (window.location.href.indexOf("?configuratore=1") > -1) {
      console.log('Check');
      if($("#3d_archi").length != 0) {
      setTimeout(
        function() 
        {
          console.log('Check scroll to');
          $('html, body').animate({ scrollTop: $('#3d_archi').offset().top}, 500);
        }, 500);
      }
    }

  }
  if( $( "body" ).hasClass( "tax-tipo_prodtax" ) ){
    const swiper_related_same_collection = new Swiper(".relatedprods_wrp_same_collection_slider", {
      loop: true,
        slidesPerView: "auto",
        spaceBetween: 22,
        centeredSlides: false,
          loop: true,
          freeMode: true,
          mousewheel: false,
          scrollbar:false,
  });


  const swiperSliderHeader = new Swiper(".header_gallery", {
    //slidesPerView: 2,
   slidesPerView: "auto",
    spaceBetween: 23,
    centeredSlides: false,
    loop: true,
    freeMode: true,
    mousewheel: false,
    scrollbar:false,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
});


  };
  

  if( $( "body" ).hasClass( "home" ) ){
    const swiperSlider_news_preview = new Swiper(".swiper_news_preview", {
      slidesPerView: 1.5,
      spaceBetween: 0,
      //centeredSlides: true,
      freeMode: false,
      breakpoints: {
        768: {
          slidesPerView: 2.5,
          centeredSlides: false,
          freeMode: true,
        },
      },
    });

    const swiper_realizzazioni_preview = new Swiper(".swiper_realizzazioni_preview", {
      loop: true,
      slidesPerView: 1,
      //spaceBetween: 20,
      centeredSlides: false,
      freeMode: true,
     breakpoints: {
      768: {
        slidesPerView: 3,
       // spaceBetween: -19,
        centeredSlides: true,
        freeMode: true,
      },
    },
  });

    //  const swiper_slider_text = new Swiper(".swiper_slider_text", {
    //     loop: true,
    //     freeMode: true,
    //     autoplay: {
    //       delay: 0,
    //       pauseOnMouseEnter:true,
    //       disableOnInteraction:false,
    //     },
    //     loopAddBlankSlides:true,
    //     //cssMode:true,
    //     slidesPerView: 2,
    //     speed: 5000,
    //     grabCursor: true,
    //     loopAdditionalSlides: 2,
    //   });

   //var textSliderTxt = document.querySelectorAll(".blocco_text_slider");

   }

   /* Singolo designer */
   if( $( "body" ).hasClass( "tax-designer_prodtax" ) ){

    const swiper_related_designer  = new Swiper(".loop_wrp_deisgner", {
      slidesPerView: "auto",
      spaceBetween: 23,
      centeredSlides: false,
      loop: true,
      freeMode: true,
      mousewheel: false,
      scrollbar:false,
    });

   };

  
   /* Single post */
   if( $( "body" ).hasClass( "single-post" ) ){
    const swiperSlider_extra = new Swiper(".prod_gallery_extra", {
      //slidesPerView: 2,
     slidesPerView: "auto",
      spaceBetween: 23,
      centeredSlides: false,
      loop: true,
      freeMode: false,
      mousewheel: false,
      scrollbar:false,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
  });

  const swiperSlider_extra2 = new Swiper(".prod_gallery_extra2", {
   slidesPerView: "auto",
    spaceBetween: 23,
    loop: true,

});

  
   };

   


   const swiperSlider_extra = new Swiper(".blocco_slider_imgs_wrp", {
    //slidesPerView: 1.8,
    slidesPerView: "auto",
    spaceBetween: 23,
    centeredSlides: false,
    loop: true,
    freeMode: true,
    mousewheel: false,
    scrollbar:false,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  /******* ↓ added ↓ ********/
  on: {
    resize: function enableOnlyDesk(swiper){
        // Disable the slider when the window width is less than or equal to 960
        if(960 < window.innerWidth){
          swiper.enable()
          swiper.el.classList.remove('-non-slider')
        }else{
          swiper.disable()
            swiper.el.classList.add('-non-slider')
            
        }
    },
}
/******* ↑ added ↑ ********/
  });

   /* Archivio Designer */
   const cursor = document.querySelector(".designers_archive_imgs");
   const cursorMedias = document.querySelectorAll(".desinger_img");
   const navLinks = document.querySelectorAll(".designers_archive a");

  if (cursor != null){
   
    gsap.set(cursor, {
      xPercent: -50,
      yPercent: -50,
      scale: 0
    });
    
    const setCursorX = gsap.quickTo(cursor, "x", {
      duration: 0.6,
      ease: "expo"
    });
    
    const setCursorY = gsap.quickTo(cursor, "y", {
      duration: 0.6,
      ease: "expo"
    });
    
    document.body.addEventListener("mousemove", (e) => {
      gsap.to(cursor, .4, {
        x: e.x - $results_per_page,
        y: e.y - $results_per_page
      })
    });
    
    const tl = gsap.timeline({
      paused: true
    });
    
    tl.to(cursor, {
      scale: 1,
      opacity: 1,
      duration: 0.5,
      ease: "expo.inOut"
    });
    
    navLinks.forEach((navLink, i) => {
      navLink.addEventListener("mouseover", () => {
        cursorMedias[i].classList.add("active");
        tl.play();
      });
    });
    
    navLinks.forEach((navLink, i) => {
      navLink.addEventListener("mouseout", () => {
        tl.reverse();
        cursorMedias[i].classList.remove("active");
      });
    });
   
  }

   /* fine Archivio Designer */


   const txt_slider = gsap.utils.toArray(".swiper_slider_text");
  
   txt_slider.forEach((text) => {
    
    const TextSlideSpeed = text.dataset.sliderspeed;
    const TextSlideDelay = text.dataset.sliderdelay;
      
      const swiper_slider_text = new Swiper(text, {
        loop: true,
        freeMode: true,
        autoplay: {
          delay: TextSlideDelay,
          pauseOnMouseEnter:true,
          disableOnInteraction:false,
        },
        loopAddBlankSlides:true,
        //cssMode:true,
        slidesPerView: 2,
        speed: TextSlideSpeed,
        grabCursor: true,
        loopAdditionalSlides: 2,
      });

  });


    

      
    // $('body').on('click', '.dropdown', function() {
    //     $(this).next('.dropdown-menu').toggleClass('d-none');
    // });
    // if($(window).width()>990) {

        $('body').on('click', '.dropdown', function(){ 
            $(this).find('.dropdown-menu').toggleClass('d-none');
        });
        // $('body').on('mouseleave', '.dropdown', function(){ 
        //     $(this).find('.dropdown-menu').addClass('d-none');
        // });

   // }

    let $intro_prev = localStorage.getItem('intro');
    if ($intro_prev  != 1){
      introHeader();
      localStorage.setItem('intro', 1);
    }

    function introHeader(){
      var tl_intro = gsap.timeline();

      tl_intro.set(".navbar-toggler", { opacity: 0 });
      tl_intro.set(".header .boxed_btn", {opacity:0});
      tl_intro.set(".headers_icon_nav a", {opacity:0});
      
  
      tl_intro.from('.scab_nav', {
          width:0,
          duration: 0.7,
      });
      tl_intro.from('.navbar-toggler', {
          opacity:0,
          duration: 0.5,
      });
      tl_intro.to('.header .boxed_btn', {
          // width:0,
          // height:0,
          opacity: 1,
          duration: 0.3,
      });
  
      tl_intro.from('.header .boxed_btn span', {
          opacity:0,
          duration: 0.5,
      });
  
      tl_intro.to('.headers_icon_nav a', {
          opacity:1,
          stagger: 0.3 
      });
    }

    var tl_hamburger= gsap.timeline({
      paused: true
    });

tl_hamburger.to('.middle_bar', {
  opacity:0,
  width:0,
  duration: 0.2,
  ease: "sine.inOut",
});
tl_hamburger.to('.top_bar', {
top:17,
rotation:45,
duration: 0.4,
ease: "sine.inOut",
},'<');
tl_hamburger.to('.bottom_bar', {
bottom:17,
rotation:-45,
duration: 0.4,
ease: "sine.inOut",
},'<');

let btn_hamburger = document.querySelector(".navbar-toggler");
btn_hamburger.addEventListener("mouseenter", (e) => {
  tl_hamburger.play()
});
btn_hamburger.addEventListener("mouseleave", (e) => {
  tl_hamburger.reverse()
});
$('body').on('click', '.navbar:not(.menu_aperto) .navbar-toggler', function(){ 
  tl_hamburger.play();
  console.log('Ci siamo 1 ');
});

$('body').on('click', '.menu_aperto .navbar-toggler', function(){ 
  tl_hamburger.reverse();
  console.log('Ci siamo 2 ');
});

var tl_menu = gsap.timeline({
paused:true
});

tl_menu.to('.scab_nav', {
  backgroundColor:'#FAFAF7',
  stagger: 0.1
})

let $minheight = '';
let $height = '';

if(window.matchMedia("(max-width: 767px)").matches){
  $minheight = '50vh';
  $height = '14rem';
}else{
  $minheight = '80vh';
  $height = '7vh';
}


tl_menu.to(".nav_top", {
      minHeight:$minheight,
      paddingTop:'4rem',
      duration: .3,
      ease:  "sine.inOut",
  });
  tl_menu.to(".nav_bottom", {
      height:$height,
      duration: .3,
      ease:  "sine.inOut",
  });
  tl_menu.to('.link_nav_big', {
      opacity:1,
      stagger: 0.2 // 0.1 seconds between when each ".box" element starts animating
  });
  
  tl_menu.to('.link_nav_small', {
      opacity:1,
      stagger: 0.2 // 0.1 seconds between when each ".box" element starts animating
  });
  tl_menu.to('.newsletter_btn', {
    opacity:1,
    duration: .1,
    ease:  "sine.inOut",
});


    $('.navbar-toggler').click(function() {
        $(this).toggleClass('collapsed');

        setTimeout(
          function() 
          {
            $('.header .navbar').toggleClass('menu_aperto');
            $('body').toggleClass('noscroll');
          }, 1000);
   


        //var tl = gsap.timeline();



        if($( this ).hasClass( "collapsed" )){

          $('.dropdown-menu').addClass('d-none');
          tl_menu.reverse();
        //   tl.to('.newsletter_btn', {
        //     opacity:0,
        //     duration: .1,
        //         ease: "sine.inOut",
        // });

        //   tl.to('.link_nav_small', {
        //         opacity:0,
        //         stagger: 0.1 // 0.1 seconds between when each ".box" element starts animating
        //     });
        //     tl.to('.link_nav_big', {
        //        //maxHeight:0,
        //      opacity:0,
        //         stagger: 0.1 // 0.1 seconds between when each ".box" element starts animating
        //     });
        //     tl.to(".nav_bottom", {
        //         height:0,
        //         duration: .1,
        //         ease: "sine.inOut",
        //     });
        //     tl.to(".nav_top", {
        //       minHeight:0,
        //         duration: .2,
        //         ease: "sine.inOut",
        //     });
        }else{
          tl_menu.play()
         
            
        }
      //  $('#navbarToggler').toggleClass('aperto');
            
    });
    
    $('.menu_nav_back').click(function() {
        $('.navbar-toggler').toggleClass('collapsed');
        $('.header .navbar').toggleClass('menu_aperto');
        $('body').toggleClass('noscroll');
        
        tl_menu.reverse();
        console.log('Ci siamo');
    });

    $('body').on('click', '.menu_aperto #header_riga1 a:not(.dropdown-toggle)', function() {
      $('.navbar-toggler').toggleClass('collapsed');
      $('.header .navbar').toggleClass('menu_aperto');
      $('body').toggleClass('noscroll');
      tl_menu.reverse();
      console.log('Ci siamo 2');
  });



  $('body').on('click', '.menu_aperto a', function() { 
   
    if($(this).hasClass( "dropdown-toggle" )){
      console.log('Ci siamo drop');
      var currentDropdown = $(this);
      if($( currentDropdown ).find('dropdown-menu').hasClass( "d-none" )){
        console.log('Ci siamo none');
        $('.dropdown-menu').each(function () {
                $(this).addClass('d-none');
                console.log('Classe aggiujnta');
        })
        $( currentDropdown ).find('dropdown-menu').removeClass('d-none');
      }else{
        $('.dropdown-menu').each(function () {
          $(this).addClass('d-none');
          console.log('Classe aggiujnta');
        })
      }
    }else{
      console.log('Ci siamo NO DROP');
      $('.dropdown-menu').each(function () {
        $(this).addClass('d-none');
        console.log('Classe aggiujnta');
      })
    }
    
    
    // if($( currentDropdown ).find('dropdown-menu').hasClass( "d-none" )){
    //   $(this).removeClass('d-none');
    // }else{
    //   //currentDropdown.find('dropdown-menu').addClass('d-none');
    // }
    
  });

      /* Header animation on start scroll */

  /* Animazione header al cambio sez. ( Scrolltrigger ) */

  let tl_scroll_section1_out = gsap.timeline({scrollTrigger:{
    trigger:".content-wrapper",
    start:"top top",
    end:"100",
    //markers: {startColor: "green", endColor: "red", fontSize: "12px"},
    scrub:1,
    invalidateOnRefresh: true
  }})

  tl_scroll_section1_out.to(".scab_nav", {
    width:'90%',
    backgroundColor:'#FAFAF7',
    ease: "sine.inOut",
    duration:1,
  })



  /* end header animation */


 


      

    // if($(window).width()<990) {
    //     $('.titolo_sidebar_shop').click(function(e) {
    //         e.preventDefault();
    //         $('.sidebar_shop').toggleClass('open');
    //     })
    // }
    
    
    /*
    $('.business_model_canvas_grid .row').each(function(index, el) {
        new Isotope( el, {
            itemSelector: '.wp-block-evidenzio-column',
            percentPosition: true,
            masonry: {
              // use outer width of grid-sizer for columnWidth
              columnWidth: '.wp-block-evidenzio-column'
            }
        })    
    });
    */

    /*
    gsap.utils.toArray(".ev_header").forEach((section) => {
        gsap
        .timeline({
          scrollTrigger: {
            trigger: section,
            start: 'top center',
            //markers: true,
          },
        })
        .from(
          $(section).find('.article_thumbnail'),
          { y: 80, 'opacity':0, duration: 1.5, stagger: .5 }
        );
    });
    */

    // $('.sidebar_shop .titolo-widget-sidebar').click(function() {
    //     $(this).toggleClass('aperto');
    //     $(this).next('.woocommerce-widget-layered-nav-list').toggleClass('aperto');
    // });
    // $('.filtro_colore').parents('.woocommerce-widget-layered-nav-list').addClass('wrapper_filtro_colore');

    $('a.scroll-link, .scroll-link>a').click(function(e) {
        e.preventDefault();
        var link = $(this).attr('href');
        $('html, body').animate({ scrollTop: $(link).offset().top-150}, 500);
    });

    

    //Fancybox.bind('.woocommerce-product-gallery__image a');

     /************************************************************** */
    /**** Check if element with class 'fadeOnScreen' is in viewport */
    /************************************************************** */

    /********************* 1° Function for check if lement is in viewport */

    let elementsinviewport = [];

    $.fn.isInViewport = function () {
        var elementTop = $(this).offset().top;
        var elementBottom = elementTop + $(this).outerHeight();

        var viewportTop = $(window).scrollTop();
        var viewportBottom = viewportTop + $(window).height();

        return elementBottom > viewportTop && elementTop < viewportBottom;
    };

    /********************* 2° Call function on load */

    checkonScreen();

    var lastScrollTop = 0;
    $(window).scroll(function (event) {
        var scroll = $(window).scrollTop();
        var header_top = $('.header').outerHeight();

        if (scroll > lastScrollTop){
          if(lastScrollTop < 0){
            $('.header').addClass('scroll_up');
            $('.header').removeClass('scroll_down');
          }else{
            $('.header').addClass('scroll_down');
            $('.header').removeClass('scroll_up');
          }
          
        } else {
          $('.header').addClass('scroll_up');
          $('.header').removeClass('scroll_down');
        }
        lastScrollTop = scroll;

        if(scroll > header_top) {
            $('body').addClass('stickyHeader');
           // $('.menu_links_wrp').addClass('headerActive');
        } else {
            $('body').removeClass('stickyHeader');
           // $('.menu_links_wrp').removeClass('headerActive');
        }
        checkonScreen();
        /* funzione per applicare fadeIn css a elementi all'entrata in viewport */
        if ($(window).scrollTop() + $(window).height() == $(document).height()) {
            elementsinviewport = [];
            
          } else if ($(window).scrollTop() == 0) {
            elementsinviewport = [];
            
          }

    });

    function checkonScreen() {
        $(".fadeOnScreen").each(function (index) {
          if ($(this).isInViewport()) {
            $(this).addClass('fadeOnScreen_active');
          } else {
            $(this).removeClass('fadeOnScreen_active');
            if( $( this ).hasClass( "archi_iframe_wrp" ) ){
              $(this).removeClass('archi_iframe_wrp_active');
            }
            
          }
        });
    }



    if($(window).width()<990) {
        $('.mobile-toggler .titolo_pb').click(function() {
            $(this).toggleClass('aperto');
            $(this).parents('.mobile-toggler').toggleClass('aperto');
        });
    }



});



if($('.scrollToTopBtn').length) {
    var scrollToTopBtn = document.querySelector(".scrollToTopBtn");
    var rootElement = document.documentElement;
    function handleScroll() {
      var scrollTotal = rootElement.scrollHeight - rootElement.clientHeight;
      if (rootElement.scrollTop / scrollTotal > 0.7) {
        scrollToTopBtn.classList.add("showBtn");
      } else {
        scrollToTopBtn.classList.remove("showBtn");
      }
    }
    function scrollToTop() {
      rootElement.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
    scrollToTopBtn.addEventListener("click", scrollToTop);
    document.addEventListener("scroll", handleScroll);
}



$(function() { // Dropdown toggle
  $('.open_login_sub').click(function(e) { 
    let position = $(this).position();
    let position_from_right = position.left;
    let position_from_top = position.top;
    $('.dropdown_login').css({
      'left' : position_from_right,
      'top' : position_from_top
    });
    // $('.dropdown_login').css('top',position_from_top );
    $('.dropdown_login').slideToggle();
    e.preventDefault();
  });
  
  $(document).click(function(e) 
  { 
  var target = e.target; 
  if (!$(target).is('.open_login_sub') && !$(target).parents().is('.open_login_sub')) 
  //{ $('.dropdown').hide(); }
    { $('.dropdown_login').slideUp(); }
  });
  });